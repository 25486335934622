import type { BonusesItems } from '../../types';
import type { Response } from '~/src/shared/lib';
import { useApi } from '@/src/services/network';

export type BonusesDTO = Response<{
	count: number;
	items: {
		id: string;
		title: string;
		picture: string;
		date_start: string;
		date_end: string;
		description: string;
		promocode: string;
		sum_from: string;
		sum_to: string;
		active: 1 | 0;
	}[];
}>;

export const toDomain = (data: BonusesDTO): BonusesItems =>
	data.result.items.map(item => ({
		id: item.id,
		title: item.title,
		image: item.picture,
		dateStart: item.date_start,
		dateEnd: item.date_end,
		description: item.description,
		promocode: item.promocode,
		sumFrom: item.sum_from,
		sumTo: item.sum_to,
		active: item.active,
	}));

export const useGetBonuses = () => {
	const api = useApi();

	return async () =>
		(await api<BonusesDTO>('/Promotions/get/')).mapRight(toDomain);
};
