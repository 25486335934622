<script setup lang="ts">
import { BaseFetchWrapper } from '@legacy-studio/library';
import { useGetBonuses } from '../core/applictation/';
import { useBonusesItems } from '../infrastructure/store';
import ListItem from './ListItem.vue';

const { pending, error } = useAsyncData(useGetBonuses());

const bonusesItems = useBonusesItems();
</script>

<template>
	<BaseFetchWrapper
		:pending="pending"
		:error="error"
	>
		<div class="flex flex-col">
			<div class="flex flex-col gap-4">
				<ListItem
					v-for="item in bonusesItems"
					:key="item.id"
					:item="item"
				/>
			</div>
		</div>
	</BaseFetchWrapper>
</template>
