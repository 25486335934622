import { apiMethods } from '../../infrastructure/api';
import { saveBonusesItems } from '../../infrastructure/store';

export const useGetBonuses = () => {
	const getBonuses = apiMethods.useGetBonuses();

	return async () => {
		const result = await getBonuses();
		if (result.isRight()) {
			saveBonusesItems(result.value);
		}
	};
};
