import dayjs from 'dayjs';
import type { BonusesItem } from '../../types';

export const getBonusesTimerInfo = (item: BonusesItem) => {
	const date1 = dayjs(item.dateEnd).utc();
	const all = date1.diff(item.dateStart, 'seconds', true);
	const past = dayjs(new Date()).utc().diff(item.dateStart, 'seconds', true);

	if (past > all) {
		return {
			past: 0,
			all: 0,
		};
	}

	return {
		past,
		all,
	};
};
